const selectTestDailySummary = (state) => {
    const { data, filters } = state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.dailySummary;
    return {
        data: [
            { name: "Breath Alcohol Screen", value: data.bas },
            { name: "Urine Drug Screen", value: data.uds },
            { name: "Oral Fluid Drug Screen", value: data.ods },
            { name: "Urine Drug Test", value: data.udt },
            { name: "Oral Fluid Collection", value: data.odt },
            { name: "Hair Drug Test", value: data.hdt },
        ],
        filters,
    };
};
const selectPatientDailySummary = (state) => {
    const { data } = state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.dailySummary;
    return [
        { name: "Male", value: data.male, percent: (data.male / data.totalGenders) * 100 },
        { name: "Female", value: data.female, percent: (data.female / data.totalGenders) * 100 },
        { name: "X", value: data.other, percent: (data.other / data.totalGenders) * 100 },
    ];
};
const selectProcessedTests = (state) => state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.processedTest;
const selectSampleResults = (state) => state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.sampleResult;
const selectListCollectionPoints = (state) => {
    const { collectionPoints } = state.authentication.app;
    return [{ collectionPointName: "All", id: null }, ...collectionPoints];
};
const selectColorScheme = (state) => state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.colorScheme;
export { selectTestDailySummary, selectPatientDailySummary, selectProcessedTests, selectSampleResults, selectListCollectionPoints, selectColorScheme, };
