var TimePeriods;
(function (TimePeriods) {
    TimePeriods[TimePeriods["Daily"] = 1] = "Daily";
    TimePeriods[TimePeriods["Weekly"] = 2] = "Weekly";
    TimePeriods[TimePeriods["Monthly"] = 3] = "Monthly";
    TimePeriods[TimePeriods["Yearly"] = 4] = "Yearly";
})(TimePeriods || (TimePeriods = {}));
var SizeView;
(function (SizeView) {
    SizeView[SizeView["Fifty"] = 6] = "Fifty";
    SizeView[SizeView["SeventyFive"] = 9] = "SeventyFive";
    SizeView[SizeView["Hundred"] = 12] = "Hundred";
})(SizeView || (SizeView = {}));
const initialTestStatistics = {
    totalSamples: 0,
    totalGenders: 0,
    male: 0,
    female: 0,
    other: 0,
    udt: 0,
    bas: 0,
    odt: 0,
    ods: 0,
    hdt: 0,
    uds: 0,
};
const initialDashboardFilterOptions = {
    selectedDate: null,
    selectedCollectionPointId: null,
    period: TimePeriods.Yearly,
    noOfRecords: SizeView.Hundred,
    startDate: null,
    endDate: null,
};
export { initialTestStatistics, initialDashboardFilterOptions, TimePeriods, SizeView };
