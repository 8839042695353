import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Dropdown, Menu, Row } from "antd";
import arrayMove from "array-move";
import * as React from "react";
import { useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import BarChartIcon from "src/assets/svg/dashboard/ic-bar.svg";
import FilterIcon from "src/assets/svg/dashboard/ic-filter.svg";
import ICSubmenuItem from "src/assets/svg/dashboard/ic-item-submenu.svg";
import PieChartIcon from "src/assets/svg/dashboard/ic-pie.svg";
import ViewCollectorIcon from "src/assets/svg/job-management/view-collector-icon.svg";
const ReorderDropdown = ({ dataSource }) => {
    const [orderedComponents, setOrderedComponents] = useState(dataSource);
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setOrderedComponents((prevOrderedComponents) => arrayMove(prevOrderedComponents, oldIndex, newIndex));
    };
    const SortableItem = SortableElement(({ label }) => (_jsxs(SortableItemWrapper, { children: [_jsxs(StyledLabel, { children: [_jsx(ViewCollectorIcon, {}), label] }), _jsx(ICSubmenuItem, {})] })));
    const SortableList = SortableContainer(({ items }) => (_jsx(SortableListWrapper, { children: items.map((label, index) => (_jsx(SortableItem, { index: index, label: label }, `item-${index}`))) })));
    const menuTables = (_jsxs(StyledMenu, { children: [_jsxs(StyledRow, { children: [_jsx(StyledColLeft, { span: 7, children: _jsx(PieChartIcon, {}) }), _jsx(StyledColRight, { span: 17, children: _jsx(BarChartIcon, {}) })] }), _jsx(Divider, {}), _jsxs(ActionRow, { children: [_jsx(StyleHeading, { children: "Show/Hide or Drag&Drop to Reorder" }), _jsx(DefaultButton, { children: "Default" })] }), _jsx(SortableList, { items: orderedComponents.map((item) => item.name), onSortEnd: onSortEnd }), _jsx(Divider, {}), _jsx(OKButton, { type: "primary", children: "OK" })] }));
    return (_jsx(Dropdown, { overlay: menuTables, trigger: ["click"], children: _jsx(DropdownButton, { icon: _jsx(FilterIcon, {}), children: "Show/Hide or Reorder" }) }));
};
export default ReorderDropdown;
const DropdownButton = styled(Button) `
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: #01b4d2;
  border: 0.025rem solid #01b4d2;
  border-radius: 0.3rem;
`;
const StyledMenu = styled(Menu) `
  padding: 1rem;
`;
const StyledRow = styled(Row) `
  border: 0.3125rem solid #ebedf0;
  margin-bottom: 0.625rem;
`;
const StyledColLeft = styled(Col) `
  display: flex;
  justify-content: center;
  border-right: 0.3125rem solid #ebedf0;
  padding: 0.3125rem;
  background: #f7f8f8;
`;
const StyledColRight = styled(Col) `
  display: flex;
  justify-content: center;
  padding: 0.3125rem;
  background: #f7f8f8;
`;
const Divider = styled.div `
  border-top: 0.125rem solid #edeef0;
  margin-bottom: 0.625rem;
`;
const ActionRow = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.3125rem;
`;
const StyleHeading = styled.p `
  font-size: 0.675rem;
  color: #7f8e9d;
`;
const DefaultButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebedf0;
  color: #2f3337;
  border-radius: 0.8125rem;
  border: none;
  padding: 0.3125rem;
  font-size: 0.625rem;
  width: 3.125rem;
  height: 1.25rem;
`;
const OKButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a8cff;
  border: none;
  border-radius: 0.3125rem;
  width: 4.375rem;
  margin: auto;
  height: 1.5625rem;
`;
const SortableItemWrapper = styled.div `
  display: flex;
  cursor: grab;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  border: 0.0625rem solid #adb3bc;
  margin-bottom: 0.625rem;
  border-radius: 0.3125rem;
  background: white;
  z-index: 9999;
  width: 100%;
`;
const StyledLabel = styled.p `
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.775rem;
`;
const SortableListWrapper = styled.div `
  padding: 0.3125rem 0;
  z-index: 99;
`;
