import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Image, Row } from "antd";
import moment from "moment";
import styled from "styled-components";
import { Text } from "src/components/base";
import { fontWeight } from "src/constants";
import { SAColourScheme } from "src/styles";
export const HeaderFile = ({ name, icon }) => (_jsx(HeaderFileWrapper, { children: _jsxs(CustomRow, { align: "middle", children: [_jsxs(Col, { xs: 24, md: 12, children: [_jsx(Image, { src: `/assets/svg/${icon}` }), _jsx(HeaderText, { name: name })] }), _jsx(Col, { xs: 24, md: 12, children: _jsx(DateText, { name: moment().format("dddd DD MMMM YYYY HH:mm") }) })] }) }));
const HeaderFileWrapper = styled.div `
  background: #2e94f2;
  margin-top: 30px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
const CustomRow = styled(Row) `
  padding: 0.8rem 2rem;

  .ant-col:nth-child(2) {
    display: flex;
    justify-content: flex-end;
  }
`;
const HeaderText = styled(Text) `
  font-size: 1rem;
  font-weight: ${fontWeight.BOLD};
  color: ${SAColourScheme.WHITE} !important;
`;
const DateText = styled(Text) `
  font-size: 0.9rem;
  color: ${SAColourScheme.WHITE} !important;
`;
