import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Progress, Select, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import SADivider from "src/components/Form/SADivider";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { TestPieChart } from "../components/charts/TestPieChart";
import { getOnsiteDailySummary } from "./store/action";
import { selectColorScheme, selectListCollectionPoints, selectPatientDailySummary, selectTestDailySummary, } from "./store/selector";
import { setDailySummaryFilter } from "./store/slice";
const DailySummaryCard = () => {
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const testSummary = useAppSelector(selectTestDailySummary);
    const patientSummary = useAppSelector(selectPatientDailySummary);
    const colorScheme = useAppSelector(selectColorScheme);
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getOnsiteDailySummary());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [testSummary.filters]);
    return (_jsxs(DailySummaryContainer, { children: [_jsxs(GroupContainer, { children: [_jsx(HeadingText, { children: "Daily Summary" }), _jsx(SADivider, {}), _jsxs(SARow, { gutter: [0, 16], children: [_jsx(SAColumn, { span: 6, align: "flex-start", children: _jsx(Typography.Text, { children: "Date" }) }), _jsx(SAColumn, { span: 18, children: _jsxs(NavigationDate, { children: [_jsx(Button, { icon: _jsx(CaretLeftOutlined, {}), onClick: () => {
                                                dispatch(setDailySummaryFilter({
                                                    selectedDate: moment(testSummary.filters.selectedDate).subtract(1, "days"),
                                                }));
                                            }, disabled: !testSummary.filters.selectedDate }), _jsx(CustomDatePicker, { format: "dd, DD MMM YYYY", suffixIcon: null, clearIcon: null, value: testSummary.filters.selectedDate ? moment(testSummary.filters.selectedDate) : null, onChange: (selectedDate) => {
                                                if (!selectedDate)
                                                    return;
                                                dispatch(setDailySummaryFilter({ selectedDate }));
                                            } }), _jsx(Button, { icon: _jsx(CaretRightOutlined, {}), onClick: () => {
                                                dispatch(setDailySummaryFilter({
                                                    selectedDate: moment(testSummary.filters.selectedDate).add(1, "days"),
                                                }));
                                            }, disabled: !testSummary.filters.selectedDate })] }) }), _jsx(SAColumn, { span: 6, align: "flex-start", children: _jsx(Typography.Text, { children: "Location" }) }), _jsx(SAColumn, { span: 18, children: _jsx(Select, { options: collectionPoints, fieldNames: { label: "collectionPointName", value: "id" }, value: testSummary.filters.selectedCollectionPointId, onChange: (value) => {
                                        dispatch(setDailySummaryFilter({ selectedCollectionPointId: value }));
                                    } }) })] })] }), _jsxs(GroupContainer, { children: [_jsx(HeadingText, { children: "Tests" }), _jsx(SADivider, {}), _jsx(TestPieChartContainer, { children: _jsx(TestPieChart, { data: testSummary.data, colors: colorScheme }) }), _jsx(SARow, { children: testSummary.data.map((item, index) => (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { span: 18, align: "flex-start", children: _jsx(ColorBox, { color: colorScheme[index], children: item.name }) }), _jsx(SAColumn, { span: 6, children: _jsx(ColorBox, { children: item.value }) })] }, index))) })] }), _jsxs(GroupContainer, { children: [_jsx(HeadingText, { children: "Patients" }), _jsx(SADivider, {}), _jsx(SARow, { children: patientSummary.map((item, index) => (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { span: 6, align: "flex-start", children: item.name }), _jsx(SAColumn, { span: 12, children: _jsx(CustomProgress, { percent: item.percent, showInfo: false, strokeColor: colorScheme[index] }) }), _jsx(SAColumn, { span: 6, align: "flex-end", children: _jsx(ColorBox, { children: item.value }) })] }, index))) })] })] }));
};
export default DailySummaryCard;
const DailySummaryContainer = styled.div `
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  min-height: 65rem;
`;
const GroupContainer = styled.div `
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const CustomDatePicker = styled(DatePicker) `
  width: 100%;
  background: #ffffff;
  border-radius: 0;
  height: 100%;

  .ant-picker-input {
    input {
      text-align: center;
    }
  }
`;
const HeadingText = styled.p `
  color: #00004c;
  font-weight: 700;
  size: 1.25rem;
  padding: 0;
  margin: 0;
`;
const NavigationDate = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  width: 100%;
`;
const TestPieChartContainer = styled.div `
  height: 20rem;
`;
const CustomProgress = styled(Progress) `
  .ant-progress-inner {
    border-radius: 0.025rem;
    height: 1rem;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    height: 1rem !important;
    border-radius: 0.025rem;
  }
`;
const ColorBox = styled.div `
  background-color: ${({ color }) => color || "#ffffff"};
  border: 0.025rem solid #ccc;
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  text-align: ${({ color }) => (color ? "left" : "center")};
  color: ${({ color }) => (color ? "#ffffff" : "#00000")};
`;
