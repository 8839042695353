import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, Dropdown, Input, Menu } from "antd";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import BarChartIcon from "src/assets/svg/dashboard/ic-bar.svg";
import ExportIcon from "src/assets/svg/dashboard/ic-export.svg";
import PieChartIcon from "src/assets/svg/dashboard/ic-pie.svg";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const ExportDropdown = ({ dataSource }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [checkAll, setCheckAll] = useState(false);
    const handleCheckboxChange = (value, checked) => {
        setCheckedItems((prev) => ({
            ...prev,
            [value]: checked,
        }));
    };
    const handleCheckAllChange = (checked) => {
        setCheckAll(checked);
        const updatedCheckedItems = dataSource.reduce((acc, item) => ({ ...acc, [item.value]: checked }), {});
        setCheckedItems(updatedCheckedItems);
    };
    const menuTables = (_jsxs(StyledMenu, { onClick: (e) => e.domEvent.stopPropagation(), children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, align: "flex-start", children: "File Type" }), _jsx(SAColumn, { span: 12, children: _jsx(Input, {}) })] }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, align: "flex-start", children: "File Name" }), _jsx(SAColumn, { span: 12, children: _jsx(Input, {}) })] }), _jsx(Divider, {}), _jsx(Menu.Item, { children: _jsx(CustomCheckbox, { checked: checkAll, indeterminate: !checkAll &&
                        Object.values(checkedItems).some((checked) => checked) &&
                        !Object.values(checkedItems).every((checked) => checked), onChange: (e) => handleCheckAllChange(e.target.checked), children: "Select All" }) }, "checkAll"), _jsx(Menu.Item, { children: _jsx(CustomCheckbox, { checked: checkedItems["img"] || false, onChange: (e) => {
                        e.preventDefault();
                        handleCheckboxChange("img", e.target.checked);
                    }, children: _jsxs(ImageContainer, { children: ["Dashboard Image", _jsx(PieChartIcon, {}), _jsx(BarChartIcon, {})] }) }) }, "img"), dataSource.map((item) => (_jsx(Menu.Item, { children: _jsx(CustomCheckbox, { checked: checkedItems[item.value] || false, onChange: (e) => {
                        e.preventDefault();
                        handleCheckboxChange(item.value, e.target.checked);
                    }, children: _jsx(LabelContainer, { children: item.name }) }) }, item.id))), _jsx(Divider, {}), _jsx(OKButton, { type: "primary", children: "Export" })] }));
    return (_jsx(Dropdown, { overlay: menuTables, trigger: ["click"], children: _jsx(DropdownButton, { icon: _jsx(ExportIcon, {}), children: "Export" }) }));
};
export default ExportDropdown;
const DropdownButton = styled(Button) `
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: #01b4d2;
  border: 0.025rem solid #01b4d2;
  border-radius: 0.3rem;
`;
const StyledMenu = styled(Menu) `
  padding: 1rem;
`;
const ImageContainer = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background: #f7f8f8;
`;
const Divider = styled.div `
  border-top: 0.125rem solid #edeef0;
  margin-bottom: 0.625rem;
`;
const CustomCheckbox = styled(Checkbox) `
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  text-align: left;

  span:not(.ant-checkbox) {
    width: 100%;
  }
  .ant-checkbox {
    width: 1rem;
  }
`;
const LabelContainer = styled.div `
  text-align: left;
  border: 0.025rem solid #edeef0;
  margin-block: 0.15rem;
  padding: 0rem 0.5rem;
`;
const OKButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a8cff;
  border: none;
  border-radius: 0.3125rem;
  width: 4.375rem;
  margin: auto;
  height: 1.5625rem;
`;
