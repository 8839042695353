const COLOR_SCHEMES = [
    {
        id: "default",
        label: "Default",
        colors: ["#00004C", "#146EA6", "#71DF7F", "#1BCBF2", "#BADC7C", "#BF700F", "#018BA6"],
    },
    {
        id: "ocean",
        label: "Ocean",
        colors: ["#001E64", "#1F5890", "#0091D5", "#A5D8DD", "#BBEDF5", "#FBB03B", "#7E909A"],
    },
    {
        id: "forest",
        label: "Forest",
        colors: ["#4A8550", "#6AB187", "#B3C100", "#CED2CC", "#53CCBD", "#71838D", "#0071D2"],
    },
    {
        id: "pastel",
        label: "Pastel",
        colors: ["#FAC196", "#BFBDCB", "#D0F0C0", "#01B4D2", "#D3C2BA", "#FD0000", "#5CD22B"],
    },
];
const collectionComponents = [
    { id: "1", name: "Test Processed", value: "testProcessed" },
    { id: "2", name: "Patient Time", value: "patientTime" },
    { id: "3", name: "Test Time", value: "testTime" },
    { id: "4", name: "Test Categories", value: "testCategories" },
    { id: "5", name: "Rapid Urine Drug Screen Results", value: "rapidUrineDrugScreenResults" },
    { id: "6", name: "Urine Drug Test Results", value: "urineDrugTestResults" },
    {
        id: "7",
        name: "Rapid Oral Fluid Drug Screen Results",
        value: "rapidOralFluidDrugScreenResults",
    },
    { id: "8", name: "Oral Fluid Drug Test Results", value: "oralFluidDrugTestResults" },
    { id: "9", name: "Hair Drug Test", value: "hairDrugTest" },
    { id: "10", name: "Breath Alcohol Test Results", value: "breathAlcoholTestResults" },
    { id: "11", name: "Drug Classes - Negative Results", value: "drugClassesNegativeResults" },
    {
        id: "12",
        name: "Drug Classes - Non Negative Results",
        value: "drugClassesNonNegativeResults",
    },
    { id: "13", name: "Drug Classes - Invalid Results", value: "drugClassesInvalidResults" },
    { id: "14", name: "Collector Performance", value: "collectorPerformance" },
    { id: "15", name: "Collector Rankings", value: "collectorRankings" },
    { id: "16", name: "Patient", value: "patient" },
    { id: "17", name: "Patient Communication", value: "patientCommunication" },
];
export { COLOR_SCHEMES, collectionComponents };
