import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { TestTypeRecordIndex } from "src/store/app/types";
import { applyCommonActionHandlers } from "../../store/slice";
import { initialOralTestingDeviceDetails } from "./types";
import { createTestingDevice, getTestingDeviceById, getTestingDevices, updateTestingDevice, } from "./action";
export const initialState = {
    listTestingDevices: [],
    listArchivedTestingDevices: [],
    selectedCollectionPointId: "",
    testingDeviceDetails: initialOralTestingDeviceDetails,
};
const oralScreenDeviceSlice = createSlice({
    name: "oralScreenDevice",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        setCollectionPointId(state, action) {
            state.selectedCollectionPointId = action.payload;
        },
        setDeviceDetails(state, action) {
            const oldData = state.testingDeviceDetails;
            state.testingDeviceDetails = {
                ...oldData,
                ...action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        applyCommonActionHandlers(builder, TestTypeRecordIndex.RapidOralFluidDrugScreen);
        builder
            .addCase(getTestingDevices.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch testing devices");
            }
            else {
                if (action.meta.arg.isArchived) {
                    state.listArchivedTestingDevices = action.payload.data;
                    return;
                }
                state.listTestingDevices = action.payload.data;
            }
        })
            .addCase(getTestingDevices.rejected, () => {
            message.error("Failed to fetch testing devices");
        })
            .addCase(createTestingDevice.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to create a new device");
            }
            else {
                message.success("Successfully created a new device");
            }
        })
            .addCase(createTestingDevice.rejected, () => {
            message.error("Failed to create a new device");
        })
            .addCase(getTestingDeviceById.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch the device information");
            }
            else {
                state.testingDeviceDetails = action.payload.data;
            }
        })
            .addCase(getTestingDeviceById.rejected, () => {
            message.error("Failed to fetch the device information");
        })
            .addCase(updateTestingDevice.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update the device");
            }
            else {
                message.success("Successfully updated the device");
            }
        })
            .addCase(updateTestingDevice.rejected, () => {
            message.error("Failed to update a new device");
        });
    },
});
export const { setResetStore, setCollectionPointId, setDeviceDetails } = oralScreenDeviceSlice.actions;
export default oralScreenDeviceSlice.reducer;
