import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker } from "antd";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import SALabeledInput from "src/components/Form/SALabeledInput";
import SASelect from "src/components/Form/SASelect";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import SampleBarChart from "../components/charts/SampleBarChart";
import { getTestSampleResultsEachType } from "./store/action";
import { selectColorScheme, selectListCollectionPoints, selectSampleResults, } from "./store/selector";
import { setSampleResultFilter } from "./store/slice";
const SampleResultCard = () => {
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const sampleResults = useAppSelector(selectSampleResults);
    const colorScheme = useAppSelector(selectColorScheme);
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getTestSampleResultsEachType());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [sampleResults.filters]);
    return (_jsxs(SampleResultContainer, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 4, align: "flex-start", children: _jsx(HeadingText, { children: "Test Sample Results" }) }), _jsx(SAColumn, { span: 20, align: "flex-end", children: _jsxs(FilterOptions, { children: [_jsx(SASelect, { fieldNames: { label: "collectionPointName", value: "id" }, options: collectionPoints, value: sampleResults.filters.selectedCollectionPointId, onChange: (value) => {
                                        dispatch(setSampleResultFilter({ selectedCollectionPointId: value }));
                                    }, children: "Location" }), _jsx(SALabeledInput, { label: "From", children: _jsx(CustomDatePicker, { suffixIcon: null, clearIcon: null, placeholder: "DD/MM/YYYY", value: sampleResults.filters.startDate ? moment(sampleResults.filters.startDate) : null, onChange: (startDate) => {
                                            if (!startDate)
                                                return;
                                            dispatch(setSampleResultFilter({ startDate }));
                                        }, disabledDate: (current) => {
                                            if (sampleResults.filters.endDate) {
                                                return current && current.isAfter(moment(sampleResults.filters.endDate), "day");
                                            }
                                            return false;
                                        } }) }), _jsx(SALabeledInput, { label: "To", children: _jsx(CustomDatePicker, { suffixIcon: null, clearIcon: null, placeholder: "DD/MM/YYYY", value: sampleResults.filters.endDate, onChange: (endDate) => {
                                            if (!endDate)
                                                return;
                                            dispatch(setSampleResultFilter({ endDate }));
                                        }, disabledDate: (current) => {
                                            if (sampleResults.filters.startDate) {
                                                return (current && current.isBefore(moment(sampleResults.filters.startDate), "day"));
                                            }
                                            return false;
                                        } }) })] }) })] }), _jsx(SampleBarChartContainer, { children: _jsx(SampleBarChart, { data: sampleResults.data, colors: colorScheme }) })] }));
};
export default SampleResultCard;
const SampleResultContainer = styled.div `
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 1rem;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const FilterOptions = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: flex-end;
  gap: 1rem;
  white-space: nowrap;
  margin-left: auto;
  .ant-select {
    min-width: 10rem;
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
  }
`;
const SampleBarChartContainer = styled.div `
  width: 100%;
  height: 20rem;
`;
const HeadingText = styled.p `
  color: #00004c;
  font-weight: 700;
  size: 1.25rem;
  padding: 0;
  margin: 0;
  padding-top: 0.3rem;
`;
const CustomDatePicker = styled(DatePicker) `
  min-width: 10rem;
`;
