import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dropdown, Menu, Radio, Space } from "antd";
import * as React from "react";
import styled from "styled-components";
import ColorSchemeIcon from "src/assets/svg/dashboard/ic-color-scheme.svg";
import { COLOR_SCHEMES } from "../typing";
const ColorSchemeSelection = ({ onSelectedScheme, }) => {
    const [selectedKey, setSelectedKey] = React.useState(COLOR_SCHEMES[0].id);
    const menu = (_jsx(Menu, { selectable: true, selectedKeys: [selectedKey], onClick: ({ key }) => {
            onSelectedScheme(COLOR_SCHEMES.find((scheme) => scheme.id === key)?.colors ?? COLOR_SCHEMES[0].colors);
            setSelectedKey(key);
        }, children: COLOR_SCHEMES.map((item) => (_jsx(Menu.Item, { children: _jsxs(Space, { direction: "vertical", children: [item.label, _jsxs(Space, { children: [_jsx(ColorBoxContainer, { children: item.colors.map((color) => (_jsx(ColorBox, { color: color }, color))) }), _jsx(Radio, { checked: selectedKey === item.id })] })] }) }, item.id))) }));
    return (_jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(DropdownButton, { icon: _jsx(ColorSchemeIcon, {}), children: "Colour Scheme" }) }));
};
export default ColorSchemeSelection;
const DropdownButton = styled(Button) `
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: #01b4d2;
  border: 0.025rem solid #01b4d2;
  border-radius: 0.3rem;
`;
const ColorBox = styled.div `
  background-color: ${({ color }) => color};
  width: 2rem;
  height: 1rem;
`;
const ColorBoxContainer = styled.div `
  width: 100%;
  display: flex;
`;
