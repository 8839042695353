import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Radio } from "antd";
import * as React from "react";
import styled from "styled-components";
import SASelect from "src/components/Form/SASelect";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { TestComposedChart } from "../components/charts/TestComposedChart";
import { getProcessedTestByPeriod } from "./store/action";
import { selectColorScheme, selectListCollectionPoints, selectProcessedTests, } from "./store/selector";
import { setProcessedTestFilter } from "./store/slice";
import { SizeView, TimePeriods } from "./store/types";
const ProcessedTestCard = () => {
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const processedTests = useAppSelector(selectProcessedTests);
    const colorScheme = useAppSelector(selectColorScheme);
    const sizeLevels = Object.values(SizeView).filter((value) => typeof value === "number");
    const handleIncrease = () => {
        const currentIndex = sizeLevels.indexOf(processedTests.filters.noOfRecords ?? 0);
        if (currentIndex < sizeLevels.length - 1) {
            dispatch(setProcessedTestFilter({ noOfRecords: sizeLevels[currentIndex + 1] }));
        }
    };
    const handleDecrease = () => {
        const currentIndex = sizeLevels.indexOf(processedTests.filters.noOfRecords ?? 0);
        if (currentIndex > 0) {
            dispatch(setProcessedTestFilter({ noOfRecords: sizeLevels[currentIndex - 1] }));
        }
    };
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getProcessedTestByPeriod());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [processedTests.filters]);
    return (_jsxs(ProcessedTestContainer, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 4, align: "flex-start", children: _jsx(HeadingText, { children: "Tests Processed" }) }), _jsx(SAColumn, { span: 20, children: _jsxs(FilterOptions, { children: [_jsx(SASelect, { fieldNames: { label: "collectionPointName", value: "id" }, options: collectionPoints, value: processedTests.filters.selectedCollectionPointId, onChange: (value) => {
                                        dispatch(setProcessedTestFilter({ selectedCollectionPointId: value }));
                                    }, children: "Location" }), _jsx(SASelect, { value: processedTests.filters.period, options: [
                                        { value: TimePeriods.Daily, label: "Daily" },
                                        { value: TimePeriods.Weekly, label: "Weekly" },
                                        { value: TimePeriods.Monthly, label: "Monthly" },
                                        { value: TimePeriods.Yearly, label: "Yearly" },
                                    ], onChange: (value) => {
                                        dispatch(setProcessedTestFilter({ period: Number(value) }));
                                    }, children: "Show" }), _jsxs(Radio.Group, { children: [_jsx(Radio.Button, { onClick: handleDecrease, disabled: processedTests.filters.noOfRecords === sizeLevels[0], children: "-" }), _jsx(Radio.Button, { onClick: handleIncrease, disabled: processedTests.filters.noOfRecords === sizeLevels[sizeLevels.length - 1], children: "+" })] })] }) })] }), _jsx(TestComposedChartContainer, { children: _jsx(TestComposedChart, { data: processedTests.data, colors: colorScheme }) })] }));
};
export default ProcessedTestCard;
const ProcessedTestContainer = styled.div `
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 1rem;
  height: auto;
`;
const TestComposedChartContainer = styled.div `
  height: 35rem;
`;
const HeadingText = styled.p `
  color: #00004c;
  font-weight: 700;
  size: 1.25rem;
  padding: 0;
  margin: 0;
`;
const FilterOptions = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-end !important;
  justify-content: flex-end;
  gap: 1rem;
  white-space: nowrap;
  margin-left: auto;

  .ant-select {
    width: 10rem;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;
