import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import React from "react";
import { Bar, BarChart, LabelList, ResponsiveContainer } from "recharts";
import styled from "styled-components";
const LegendName = {
    negative: "Negative",
    nonNegative: "Non Negative",
    invalid: "Invalid",
    sentToLab: "Sent to Lab",
    refusal: "Refusal",
};
const CustomLegend = ({ colors }) => (_jsx(LegendContainer, { children: Object.values(LegendName).map((name, index) => (_jsxs(LegendItem, { children: [_jsx(LegendMarker, { color: colors[index] }), _jsx(LegendText, { children: name })] }, name))) }));
const SampleBarChart = ({ data, colors }) => (_jsxs(React.Fragment, { children: [_jsx(CustomLegend, { colors: colors }), _jsx(ChartContainer, { children: data.map((item, index) => (_jsxs(ChartItem, { children: [_jsx(ChartWrapper, { children: _jsx(ResponsiveContainer, { children: _jsx(BarChart, { data: [item], margin: { top: 30, right: 10, left: 10, bottom: 0 }, children: Object.keys(LegendName).map((key, i) => (_jsx(Bar, { dataKey: key, fill: colors[i], children: _jsx(LabelList, { dataKey: key, position: "top", fill: "#000" }) }, key))) }) }) }), _jsx(ChartLabel, { children: item.label })] }, index))) })] }));
export default SampleBarChart;
const LegendContainer = styled.div `
  display: flex;
  gap: 15px;
  padding: 1rem;
`;
const LegendItem = styled.div `
  display: flex;
  align-items: center;
  gap: 5px;
`;
const LegendMarker = styled.div `
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;
const LegendText = styled.span `
  font-size: 14px;
`;
const ChartContainer = styled.div `
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-between;
  overflow-x: auto;
  margin-inline: -1rem;
`;
const ChartItem = styled.div `
  flex: 0 0 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;
const ChartWrapper = styled.div `
  width: 22vh;
  height: 80%;
  border: 1px solid #ccc;
`;
const ChartLabel = styled(Typography.Text) `
  text-align: center;
`;
