import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
const SALabeledInput = ({ children, label }) => (_jsxs(LabelContainer, { children: [_jsx(LabelContent, { children: label }), children] }));
const LabelContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;
const LabelContent = styled.p `
  white-space: nowrap;
`;
export default SALabeledInput;
