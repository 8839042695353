import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import useExtendPath from "src/hooks/useExtendPath";
import CollectionDashboard from "./Collections";
import DeviceDashboard from "./Devices";
import SatchelDashboard from "./Satchels";
const DashboardPage = () => {
    const location = useLocation();
    const appendPath = useExtendPath();
    useEffect(() => {
        if (location.pathname === "/dashboard") {
            appendPath("collections");
        }
    }, [location]);
    return (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/collections/*", element: _jsx(CollectionDashboard, {}) }), _jsx(Route, { path: "/devices", element: _jsx(DeviceDashboard, {}) }), _jsx(Route, { path: "/satchel", element: _jsx(SatchelDashboard, {}) })] }) }));
};
export default DashboardPage;
