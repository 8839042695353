import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from "react-router-dom";
import useAllTabsClosed from "src/hooks/useAllTabClose";
export const MasterRouteLayout = () => {
    const openFreshworksWidget = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (window.FreshworksWidget) {
            if (process.env.ENV !== "production") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                window.FreshworksWidget("destroy");
            }
            else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                window.FreshworksWidget("close");
            }
        }
    };
    useAllTabsClosed();
    // That's the freshdesk integration. please don't remove it
    openFreshworksWidget();
    return _jsx(Outlet, {});
};
