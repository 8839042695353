import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { stringify } from "qs";
import { $get } from "src/infra/http";
import { Domain } from "src/types";
const getOnsiteDailySummary = createAsyncThunk("dashboard/getOnsiteDailySummary", async (_, thunkApi) => {
    const state = thunkApi.getState().dashboardPage.collectionDashboardPage;
    const { selectedDate, selectedCollectionPointId } = state.onsiteCollectionDashboard.dailySummary.filters;
    let startDate = null;
    let endDate = null;
    if (selectedDate) {
        const givenMomentUTC = moment.utc(selectedDate);
        startDate = givenMomentUTC.clone().startOf("day").toISOString();
        endDate = givenMomentUTC.clone().endOf("day").toISOString();
    }
    const queryString = stringify({
        collectionPointId: selectedCollectionPointId,
        startDate,
        endDate,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/daily-summary?${queryString}`, Domain.CM);
});
const getProcessedTestByPeriod = createAsyncThunk("dashboard/getProcessedTestByPeriod", async (_, thunkApi) => {
    const state = thunkApi.getState().dashboardPage.collectionDashboardPage;
    const { period, noOfRecords, selectedCollectionPointId } = state.onsiteCollectionDashboard.processedTest.filters;
    const queryString = stringify({
        collectionPointId: selectedCollectionPointId,
        period: period ?? 1,
        noOfRecords,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/processed-test?${queryString}`, Domain.CM);
});
const getTestSampleResultsEachType = createAsyncThunk("dashboard/getTestSampleResultsEachType", async (_, thunkApi) => {
    const state = thunkApi.getState().dashboardPage.collectionDashboardPage;
    const { startDate, endDate, selectedCollectionPointId } = state.onsiteCollectionDashboard.sampleResult.filters;
    const queryString = stringify({
        collectionPointId: selectedCollectionPointId,
        startDate,
        endDate,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/sample-result?${queryString}`, Domain.CM);
});
export { getOnsiteDailySummary, getProcessedTestByPeriod, getTestSampleResultsEachType };
