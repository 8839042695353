import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from "antd";
import React from "react";
import styled from "styled-components";
const SASelect = ({ children, ...rest }) => (_jsxs(SelectContainer, { children: [children && _jsx(LabelContent, { children: children }), _jsx(Select, { ...rest })] }));
const SelectContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;
const LabelContent = styled.p `
  white-space: nowrap;
`;
export default SASelect;
