import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { COLOR_SCHEMES } from "../../typing";
import { getOnsiteDailySummary, getProcessedTestByPeriod, getTestSampleResultsEachType, } from "./action";
import { initialDashboardFilterOptions, initialTestStatistics, } from "./types";
export const initialState = {
    dailySummary: {
        filters: initialDashboardFilterOptions,
        data: initialTestStatistics,
    },
    processedTest: {
        filters: initialDashboardFilterOptions,
        data: [],
    },
    sampleResult: {
        filters: initialDashboardFilterOptions,
        data: [],
    },
    colorScheme: COLOR_SCHEMES[0].colors,
};
const onsiteCollectionDashboardSlice = createSlice({
    name: "onsiteCollectionDashboard",
    initialState,
    reducers: {
        setDailySummaryFilter(state, action) {
            state.dailySummary.filters = { ...state.dailySummary.filters, ...action.payload };
        },
        setProcessedTestFilter(state, action) {
            state.processedTest.filters = { ...state.processedTest.filters, ...action.payload };
        },
        setSampleResultFilter(state, action) {
            state.sampleResult.filters = { ...state.sampleResult.filters, ...action.payload };
        },
        setColorScheme(state, action) {
            state.colorScheme = action.payload ?? COLOR_SCHEMES[0].colors;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOnsiteDailySummary.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.dailySummary.data = data;
        })
            .addCase(getOnsiteDailySummary.rejected, () => {
            message.error("Failed to fetch the onsite summary");
        })
            .addCase(getProcessedTestByPeriod.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.processedTest.data = data;
        })
            .addCase(getProcessedTestByPeriod.rejected, () => {
            message.error("Failed to fetch the onsite processed tests");
        })
            .addCase(getTestSampleResultsEachType.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.sampleResult.data = data;
        })
            .addCase(getTestSampleResultsEachType.rejected, () => {
            message.error("Failed to fetch the onsite sample results");
        });
    },
});
export const { setDailySummaryFilter, setProcessedTestFilter, setSampleResultFilter, setColorScheme, } = onsiteCollectionDashboardSlice.actions;
export default onsiteCollectionDashboardSlice.reducer;
